import React from 'react';
import { Collapse, IconButton, TextField, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Close, Edit } from '@material-ui/icons';
import { readProfile, updateProfile } from '../Data/Profile';
import { fileToBase64} from '../Data/Image';
import { getOffsetInScreen, randomString } from '../Functions';
import ss from '../Data/Session';

import './Profile.scss';

const invalid_addressid_pattern = /[^0-9a-zA-Z.\-_]/;

/**
 * @typedef {import("../Data/Profile").profileType} profileType
 * @typedef {import('../VirtualRouter').point} point
 * 
 * @param {{
 *  givenProfile: profileType,
 *  windowSize: import('../VirtualRouter').size,
 * }} props
 */
const Profile = ({givenProfile, windowSize}) => {

    const [uniqueId,] = React.useState(randomString(16));

    const [session,setSession] = React.useState(ss.getCurrentSession());
    React.useEffect(()=>{
        let isMount = true;
        const sessionUpdate = ()=>{
            if (isMount){
                setSession(ss.getCurrentSession());
            }
        }
        ss.registerSessionCallback(sessionUpdate)
        return ()=>{
            isMount = false;
        }
    },[]);

    const [profile, setProfile] = React.useState(givenProfile);
    React.useEffect(()=>{
        setProfile(givenProfile);
    },[givenProfile])
    React.useEffect(()=>{
        let isMount = true;
        (async()=>{
            if (isMount && profile.addressid && session.userid){
                setProfile(await readProfile(profile.addressid));
            }
        })();
        return ()=>{
            isMount = false;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session.userid]);

    /** @type {[ 'addressid'|'email'|'image'|'name'|'none' , React.Dispatch<React.SetStateAction<'addressid'|'email'|'image'|'name'|'none'>> ]} */
    const [currentTarget, setCurrentTarget] = React.useState('none');
    const [isEditing, setIsEditing] = React.useState(false);
    React.useEffect(()=>{
        if (isEditing && currentTarget === 'image'){
            document.getElementById(uniqueId+'_newImage').click();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isEditing]);
    
    const [newName, setNewName] = React.useState(profile.name);
    const [newAddressid, setNewAddressId] = React.useState(profile.addressid);
    const [newEmail, setNewEmail] = React.useState(session.email);
    const [errorOnAddressid, setErrorOnAddressid] = React.useState(false);

    React.useEffect(()=>{
        setNewName(profile.name);
        setNewAddressId(profile.addressid);
    },[profile])

    React.useEffect(()=>{
        let isMount = true;
        if (isMount){
            setNewEmail(session.email);
        }
        return ()=>{
            isMount = false;
        }
    },[session])


    const [errorMessage, setErrorMessage] = React.useState('');

    /** @type {[ point , React.Dispatch<React.SetStateAction<point>> ]} */
    const [lastKnownPosition, setLastKnownPosition] = React.useState({x:0,y:0});

    const moveButton = (event, target) => {
        if (isEditing){
            return;
        }
        let motherDomBox = getOffsetInScreen(document.getElementById(uniqueId));
        let domBox = getOffsetInScreen(event.target);
        setLastKnownPosition({x: domBox.left - motherDomBox.left + domBox.width - 10, y: domBox.top - motherDomBox.top});
        setCurrentTarget(target);
    }
    
    return (<div id={uniqueId} className={"Profile"}>
        <Collapse in={errorMessage.length > 0}>
            <Alert severity="error" action={<IconButton size={"small"} onClick={()=>setErrorMessage("")}><Close/></IconButton>}>{errorMessage}</Alert>
        </Collapse>
        <div className={"RowContainer"}>
            <div className={"Image"}>
                <img src={profile.image} alt={profile.name}
                    onMouseEnter={(event) => {
                        moveButton(event,'image');
                        
                    }}
                    onClick={(event)=>{
                        moveButton(event,'image');
                    }}
                />
                <input type={"file"} name={"newImage"} id={uniqueId+"_newImage"} onChange={async ()=> {
                    let data = await fileToBase64(document.getElementById(uniqueId+"_newImage").files[0])
                    let newProfile = await updateProfile({image: data})
                    if (newProfile instanceof Error){
                        setErrorMessage(newProfile.message);
                    }else if (newProfile){
                        setProfile(newProfile);
                        ss.sessionRefresh();
                    }
                    setIsEditing(false);
                }}/>
            </div>
        </div>
        <div className={"RowContainer"}>
            {(isEditing && currentTarget === "name")
            ?<TextField autoFocus inputProps={{style:{textAlign:'center'}}} className={"textfield"} value={newName} onLoad={(event)=>{event.target.querySelector('input').focus()}} onChange={(event) => {
                setNewName(event.target.value);
            }} onSuspend
             onBlur={async ()=>{
                let newProfile = await updateProfile({name: newName});
                if (newProfile instanceof Error){
                    setErrorMessage(newProfile.message);
                }else if (newProfile){
                    setProfile(newProfile);
                    ss.sessionRefresh();
                }
                setIsEditing(false);
            }} onKeyPress={(event)=>{
                if (event.key === "Enter"){
                    event.target.blur();
                }
            }}/>
            :<div className={"Text"}
                onMouseEnter={(event) => {
                    moveButton(event,'name');
                }}
                onClick={(event)=>{
                    moveButton(event,'name');
                }}
            >
                {profile.name}
            </div>}
        </div>
        <div className={"RowContainer"}>
            
            {(isEditing && currentTarget === "addressid")
            ?<TextField autoFocus inputProps={{style:{textAlign:'center'}}} error={errorOnAddressid} className={"Textfield"} value={newAddressid} onLoad={(event)=>{event.target.querySelector('input').focus()}} onChange={(event) => {
                if (invalid_addressid_pattern.exec(event.target.value)){
                    setErrorOnAddressid(true);
                    return;
                }
                setErrorOnAddressid(false);
                setNewAddressId(event.target.value);
            }} onBlur={async ()=>{
                let newProfile = await updateProfile({addressid: newAddressid});
                if (newProfile instanceof Error){
                    setErrorMessage(newProfile.message);
                }else if (newProfile){
                    setProfile(newProfile);
                    ss.sessionRefresh();
                }
                setIsEditing(false);
            }} onKeyPress={(event)=>{
                if (event.key === "Enter"){
                    event.target.blur();
                }
            }} helperText={errorOnAddressid?"사용가능 문자: 0-9 a-z A-Z ._-":""}/>
            :<div className={"Text"} style={{color: !profile.addressid?'rgba(0,0,0,0.45)':'inherit' }}
                onMouseEnter={(event) => {
                    moveButton(event,'addressid');
                }}
                onClick={(event)=>{
                    moveButton(event,'addressid');
                }}
            >
                {profile.addressid || "공개 ID 없음"}
            </div>}
        </div>
        <div className={"RowContainer"}>
            {(isEditing && currentTarget === "email")
            ?<TextField autoFocus inputProps={{style:{textAlign:'center'}}} value={newEmail} className={"Textfield"} onLoad={(event)=>{event.target.querySelector('input').focus()}} onChange={(event) => {
                setNewEmail(event.target.value);
            }} onBlur={async ()=>{
                let newProfile = await updateProfile({email: newEmail});
                if (newProfile instanceof Error){
                    setErrorMessage(newProfile.message);
                }else if (newProfile){
                    await ss.sessionRefresh();
                }
                setIsEditing(false);
            }} onKeyPress={(event)=>{
                if (event.key === "Enter"){
                    event.target.blur();
                }
            }}/>
            :<div className={"Text"} style={{ color: !session.email?'rgba(0,0,0,0.45)':'inherit' }}
                onMouseEnter={(event) => {
                    moveButton(event,'email');
                }}
                onClick={(event)=>{
                    moveButton(event,'email');
                }}
            >
                {session.email || "email 없음"}
            </div>}
        </div>
        {!isEditing && currentTarget !== "none" && (session.userid === givenProfile.userid)
        ?<Tooltip title={
            currentTarget==="image"     ?"프로필 사진입니다. 640x640 이상의 사진이 권장됩니다. 프로필 페이지, 댓글 등에 노출됩니다."
            :currentTarget==="name"     ?"자유로운 형식의 이름입니다. 프로필 페이지, 댓글 등에 노출됩니다."
            :currentTarget==="addressid"?"영어 알파벳과 ._-만 허용되는 이름입니다. 접근 주소로 사용됩니다."
            :/*currentTarget==="email"?*/"이메일주소입니다. 외부에 노출되지 않습니다."
        }>
            <IconButton
                size={"small"}
                className={"EditButton"}
                style={{ left: lastKnownPosition.x, top: lastKnownPosition.y }}
                onClick={()=>{setIsEditing(true)}}
            ><Edit className={"EditButton"}/></IconButton>
        </Tooltip>
        :<></>}
    </div>);
}

export default Profile;