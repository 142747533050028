import React, { useState, useEffect } from "react";
import {read, askAsCOTStep1, askAsCOTStep2, AnswerChatDirect} from '../Data/ChatGPT';
import { Button, Paper, TextField } from "@material-ui/core";
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

import './GPT.scss';

const COT = () => {

    useEffect(()=>{
        document.title = "Anthropic COT: Chain of Thought";
    },[]);

    /**
     * @type {[
     *   {role: 'system'|'user'|'assistant', content: string}[],
     *   React.Dispatch<SetStateAction<{role: 'system'|'user'|'assistant', content: string}[]>>
     * ]}
     */
    const [chat, setChat] = useState([
        {role: 'system', content: `
        | You think through below steps and responses to the chat.
        |
        | 1. Always develop your logic in minimum 1 to maximum 5 steps.
        |     - Simple: take only 'conclusion' step. In this case you should label it as [Conclusion].
        |     - Complex: start by outlining your approach. Make it MECE or procedural 2-3 sub-problems. Label above this section "[Logic]\\n".
        | 2. For each sub-problem, label with "[Step {number}]\\n".
        | 3. Finally, Summarize your thought step and provide clear answer in reverse-pyramid style. Label above this "[Conclusion]\\n".
        |     - Aggregate all facts and imporant logics into [Conclusion](Let user doesn't need to read all arguments).
        |     - Make sure to provide summary of your thought process in [Conclusion].
        | 4. Respond only one part(logic / step / conclusion) at a time. Do not give multiple part at once.
        |     - It means If you give Logic, you should not give Step or Conclusion at the same time. If you can answer at a time, just give [Conclusion].
        | 5. If user asks to break logic, you can fix logic and label it "[Logic]\\n, and answer user's question".
        | 6. Finally you will provide requested output.
        |     - If user asked for a code, you will provide a code.
        |     - If user asked for a logical structure, you will provide a logical structure.
        |     - If user asked short answer, you will provide a short answer.
        |
        | Your repsonse should:
        | 1. not try to fill full tokens unless you have enough LLM tokens to respond.
        | 2. Use three to five meaningful words to consist first sentence of each paragraph(to make it easier to read).
        |     - Also, Your first sentence address the main point of the paragraph.
        | 3. Use Markdown syntax(with hypen and four space indentation, triple new line to seperate paragraph) and full sentence to be read fluently.
        | 4. Correct user's wrong information or nouns and keep the conversation on track.
        | 5. Never repeat parts of the question in your answer unless you are changing logic.
        | 6. Be matched with user's requested format.
        |     - If user asked for a code, you will provide a code.
        |     - If user asked for a logical structure, you will provide a logical structure.
        |     - If user asked short answer, you will provide a short answer.
        |`.replace(/ *\n +\|/g, '\n')}
    ]);
    const [content, setContent] = useState('');
    const [needToRetry, setNeedToRetry] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [, setElapsedTimer] = useState(null);

    /**
     * @type {[
     *   'waiting'|'first_response'|'critic'|'reflection',
     *   React.Dispatch<SetStateAction<'waiting'|'first_response'|'critic'|'reflection'>>
     * ]}
     * */
    const [stage, setStage] = useState('waiting');

    const [messageId, setMessageId] = useState(null);

    const pollMessage = async (messageId) => {
        const [content, streaming] = await read({ messageId }) || [null];
        return { content, streaming };
    };

    const updateChat = (chat, messageId, content) => {
        const idx = chat.findIndex(row => row.messageId === messageId);
        if (idx >= 0) {
            return [
                ...chat.slice(0, idx),
                { ...chat[idx], content },
                ...chat.slice(idx + 1)
            ];
        }
        return [...chat, { role: 'assistant', content, messageId }];
    };

    const cleanupAndCheckConclusion = (content) => {
        setStage('waiting');
        setMessageId(null);
        setNeedToRetry(0);
        setElapsedTimer(timer => {
            clearInterval(timer);
            return null;
        });
      
        if (!content.includes("[Conclusion]") && (content.includes("[Logic]") || content.includes("[Step")) ){
            setTimeout(() => {
                setContent("(meta conversation-consider as pseudo system prompt) continue to reach the conclusion");
                document.getElementById('ask').click();
            }, 400);
        }
    };

    useEffect(()=>{
        if (!messageId){
            return;
        }

        const intervalLength = 200;
        const interval = setInterval(async () => {
            setNeedToRetry(prev => prev + intervalLength / 1000);
            const { content, streaming } = await pollMessage(messageId);

            if (content){
                setChat(chat => updateChat(chat, messageId, content));

                if (!streaming){
                    cleanupAndCheckConclusion(content);
                }
            }
        }, intervalLength);

        return ()=> {
            clearInterval(interval);
        }
    },[messageId])

    useEffect(()=>{
        if (needToRetry > 100){
            setMessageId(null);
        }
    },[needToRetry])
    return (<div style={{width: "calc(100% - 40px)", maxWidth: 800, margin: "0 auto", padding: 20, backgroundColor:'gray'}}>
        {chat.filter(row => row.role !== 'system').map((message, idx) =>
        <Paper
            style={{
                margin: 10, wordBreak:'keep-all', padding: 10,
                ...(message.role !== 'user' && !message.content.includes('[Conclusion]')?{color: 'rgba(200,200,200,1)'}:{})
            }} key={idx}
        >
            <div style={{fontWeight: 'bold'}}>
                {message.role} {(message.content === "(meta conversation-consider as pseudo system prompt) continue to reach the conclusion") && <span style={{fontSize: '80%', fontStyle:"italic"}}>{"계속"}</span> }
            </div>
            <div>
                {(message.content !== "(meta conversation-consider as pseudo system prompt) continue to reach the conclusion")
                && <Markdown remarkPlugins={[gfm]}>{message.content}</Markdown> }
            </div>
        </Paper>)}
        <TextField fullWidth multiline
            value={content} onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => {
                if(e.key === 'Enter' && e.ctrlKey && needToRetry === 0){
                    e.target.blur();
                    document.getElementById('ask').click();
                }
            }}
        />
        {needToRetry === 0
        ?<Button id={"ask"} variant="contained" style={{margin: '10px 10px', width: 'calc(100% - 20px)'}}
            disabled={content.length === 0 && chat.length === 1}
            onClick={()=>{
                setNeedToRetry(1);
                const newChat = [
                    ...chat,
                    {role: 'user', content: content || "(meta conversation-consider as pseudo system prompt) continue to reach the conclusion"}
                ]
                setChat(newChat);
                setContent("");

                (async()=>{
                    setElapsedTime(0);
                    setElapsedTimer(setInterval(()=>{
                        setElapsedTime(elapsedTime => elapsedTime + 100);
                    }, 100));
                    let chat = newChat;
                    setStage('first_response');
                    let firstAnswer = await AnswerChatDirect({chat, max_tokens: 8192});
                    console.log("첫 응답입니다. 자기 비평을 통해 가다듬은 답안을 제시하겠습니다:\n"+firstAnswer);

                    setStage('critic');
                    let criticResp = await askAsCOTStep1({chat, firstAnswer, max_tokens: 8192});
                    console.log("자기비평입니다. 곧 최종 응답을 제시하겠습니다:\n"+criticResp);

                    setStage('reflection');
                    let reflectedMessageId = await askAsCOTStep2({chat, firstAnswer, criticResp, max_tokens: 8192});
                    setMessageId(reflectedMessageId);
                })();
            }}
        >{`${content.length > 0?"물어보기":"계속하기"} ${elapsedTime>0?`(지난 답변에서 ${`${elapsedTime / 1000}`.substring(0,4)} 초 소요)`:''}`}</Button>
        :`${stage === 'first_response'?'첫 응답을 생각중입니다: '
            :stage === 'critic'?'자기 비평 중입니다: '
            :stage === 'reflection'?'최종 응답을 생각중입니다: '
            :''}${`${elapsedTime / 1000}`.substring(0,4)}초 째 기다리는 중... (자세한 내용은 개발자 콘솔을 확인하세요)`}
    </div>);
}

export default COT;