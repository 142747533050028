import React from 'react';
import VirtualRouter from './VirtualRouter';

import { MuiThemeProvider, createTheme, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Provider } from 'react-redux';
import store from './store';
import { fn } from './Functions';
import Toast from './Component/Toast';

/**
 * 
 * @typedef {{
  *   isClient: boolean, injectedLocation: string,
  *   session: import('./Data/Session').sessionType,
  * }} globalDataType 
  */

/**
 * 
 * @param {{isClient: boolean, injectedLocation: string}} props
 * @return {JSX.Element}
 */
const App = (props) => {
    
    /** @type {[sessionType, (newSession:sessionType)=>void]} */
    const [session, ] = React.useState({});

    const [alertText, setAlertText]= React.useState(null);
    const [toastMessage, setToastMessage] =React.useState(undefined);
    const [toastTimeout, setToastTimeout] =React.useState(0);
    const alert = React.useMemo(()=>(message)=>setAlertText(message),[setAlertText]);
    React.useEffect(()=>{
        fn.alert = alert;
        fn.toastAlert = (message, timeout = 1000) => {setToastMessage(message); setToastTimeout(timeout+Math.random())};
    },[alert])



    /** @type {globalDataType} */
    const routingProps = {
        isClient: props.isClient, injectedLocation: props.injectedLocation,
        session,
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(0,176,240)',
            }
        },
        overrides: {
            MuiButton: {
                containedPrimary: {
                    color: 'white',
                },
            },
        }
    })

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <div className="App">
                    <VirtualRouter {...routingProps} />
                    <Toast timeout={toastTimeout}>
                        {toastMessage}
                    </Toast>
                </div>
            </MuiThemeProvider>
            <Dialog open={!!alertText}>
                <DialogContent>{alertText}</DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={()=>setAlertText(null)}>{"확인"}</Button>
                </DialogActions>
            </Dialog>
        </Provider>
    );
}

export default App;
