import axios from "axios";

export const Translate = async ({source, target, text}) => {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/translation', {source, target, text}, {
        });

        if (res.data && res.data.code === 200){

            return res.data.data;
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}
