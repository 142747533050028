import { Button, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import React from 'react';
import { AnswerChat, CompleteText, } from '../Data/ChatGPT';
import { ImageGeneration } from '../Data/ImageGeneration';
import { Translate } from '../Data/Translation';

import './ScriptGen.scss';

const intro_of_stroy =
`The story follows 12 step of Hero's Journey, but every word and comment is metaphors.
This is the setting of this story, We will make a new story.
`;

const steps = [
`0. Intro.
This is an introduction of this story. We will reuse Key points from it. I will give you twelve requests, thus never make whole story at once.
`,
`1. Ordinary world.
This is begining of heroic story. 'The hero/heroin', who is not so brave but common, is living peacefully. You need to describe 'The hero/heroin' and who is in this scene.
`,
`2. Call to adventure.
Someday 'The hero/heroin' is falling into danger or encounter a huge evil enemy 'Evil'. He is trying to escape from it, but he cannot. He lose 'Important one'. You need to describe 'The hero/heroin' and who is in this scene.
`,
`3. Refusal of the call.
'The hero/heroin' do not think he can overcome it. But he became a loser and is wasting times. You need to describe 'The hero/heroin' and who is in this scene.
`,
`4. Meeting with the mentor.
A guy who is smart and experienced enough, who called 'Mentor', come in front fo 'The hero/heroin'. The mentor has same sex with 'The hero/heroin'. He/she says 'The hero/heroin' must overcome it and go to adventure to get back all. You need to describe 'The hero/heroin' and who is in this scene.
`,
`5. Crossing the threshold.
'Mentor' says 'The hero/heroin' should go to somewhere to become a real hero. He guide 'The hero/heroin' to do something that seems contraversal but it has impressive meaning. You need to describe 'The hero/heroin' and who is in this scene.
`,
`6. Tests, allies and enemies.
From the start of this section, 'The hero/heroin' met a weak enemy and he overcome it. When he is became bumptious, much more enemies come to him and hurt him. It must be hard, but he defeated them. You need to describe 'The hero/heroin' and who is in this scene.
`,
`7. Approach to the inmost cave.
After he overcome most of enemies, It becames clear there is the 'Important one' which is 'The hero/heroin' looking for. He approaches to the place, but closer and closer, there are more enemies. The place is not a cave, cave is a metaphor, you need to suggest some place or a good goal for the heroic character. You need to describe 'The hero/heroin' and who is in this scene.
`,
`8. The ordeal.
There will be a last fight. 'The hero/heroin' and 'Evil' will battle to take 'Important one'. At last. 'The hero/heroin' wins. You need to describe 'The hero/heroin' and who is in this scene.
`,
`9. Reward.
'The hero/heroin' got back 'Important one', and he satisfied. He want to go back home. You need to describe 'The hero/heroin' and who is in this scene.
`,
`10. Road back.
'The hero/heroin' is coming back home. He seems little bit tired, old, but has great confidence. You need to describe 'The hero/heroin' and who is in this scene.
`,
`11. The resurrection.
'The hero/heroin' meet a major threat. He cannot miss the attack of it, and He lose 'Important one' again. But, by his friend's sacrifice, he gain power again and defeat the threat again. You need to describe 'The hero/heroin' and who is in this scene.
`,
`12. Return with the elixir.
'The hero/heroin' come back to home, and he has some magical item: he seems can do anything and has much confidence. Everyone worship him. You need to describe 'The hero/heroin' and who is in this scene.
`
];

const prompt_prefix = "";
const prompt_postfix = [
    // "color-filled margin 0.3",
    // "a scene from a professional animation",
    // "cinematic lighting",
    // "funny crazy atmosphere",
    "professional manga illust",
    "multiple person",
    "long shot",
    "studio lighting delicate features finely detailed perfect art",
    "longbody -1",
    "lowres -1",
    "bad anatomy -1",
    "bad hands -1",
    "missing fingers -1",
    "extra digit -1",
    "fewer digits -1",
    "cropped -1",
    "worst quality -1",
    "low quality -1"
].join("|");


// eslint-disable-next-line no-empty-pattern
const ScriptAndCut = ({}) => {
    
    const [isLoading, setIsLoading] = React.useState(false);
    const [isKoreanLoading, setIsKoreanLoading] = React.useState(false);

    const [setting, setSetting] = React.useState("");
    const [engSetting, setEngSetting] = React.useState("");
    const [prompt, setPrompt] = React.useState("");

    const [keySentenses, setKeySentenses] = React.useState([]);
    const [koreanSentences, setKoreanSentenses] = React.useState([]);
    const [images, setImages] = React.useState({});

    const [model, setModel] = React.useState('vintedois');

    React.useEffect(()=>{

        if (keySentenses.length === 0){
            return;
        }
        
        (async()=>{
            setIsLoading(true);
            let index = keySentenses.length-1;
            const lastSentence = keySentenses[index];
            /*
            let sceneDescription = await CompleteText({
                prompt: lastSentence.trim()
                    +" following this, describe a long shot, outfit and circumstances in thirty words."
                    +" The description will be composed with short sentences that is answering folloing questions:"
                    +" what are they doing. background description. how the light ray to be seemed."
                    +" The background of scene should related with: '"+engSetting+"'"
                    +" use notation '(the man)' if 'the heroic character' is a man or use '(the girl)' if 'the heroic character' is a woman, to represent 'the heroic character'. Do not use a name of heroic character."
                    +` describe extra character and what are they doing and their emotion on face, relevent to '${lastSentence.trim()}'.`
                    +` Use devices or gadgets as metaphor to describe some abstract values like leadership, strength, love, or etc.`
                    +"\nnew description:",
                max_tokens: 1024
            });*/
            let sceneDescription = await AnswerChat({
                chat: [
                    {role: 'system', content: lastSentence},
                    {role: 'user', content: 
                        " following this, describe a long shot, outfit and circumstances."
                        +" Your sentence must be short and clear, like: A young man is standing., A young man is wearing blue T-shirts., or something like this."
                        +" You must describe more than two person."
                        +" The description will be composed with short sentences that is answering folloing questions:"
                        +" what are they doing. background description. how the light ray to be seemed."
                        +" The background of scene should related with: '"+engSetting+"'"
                        +" use notation '(the man)' if 'the heroic character' is a man or use '(the girl)' if 'the heroic character' is a woman, to represent 'the heroic character'. Do not use a name of heroic character."
                        +` describe extra character's wearing and position and gesture. But do not add any line. relevent to '${lastSentence.trim()}'.`
                        +` Use devices or gadgets as metaphor to describe some abstract values like leadership, strength, love, or etc.`
                    }
                ]
            })
            let res = await ImageGeneration({model, query: [sceneDescription.replace(/\n/g,' ')+' 3.0', prompt_prefix, prompt_postfix].filter(prompt => prompt).join('|')});
            setImages(images => ({
                ...images,
                [index]: {description: sceneDescription, image: res},
            }))
            setIsLoading(false);
        })();
        
        if (keySentenses.length >= steps.length+1){
            return;
        };

        (async()=>{
            try{
                //let script = intro_of_stroy + 'setting:' + engSetting;
                let chat = [
                    {role: 'system', content: intro_of_stroy},
                    {role: 'system', content: 'setting:' + engSetting},
                    {role: 'user', content: 
                        `I want to write a script with 12 steps of hero. the first line will be: ${keySentenses[0]}.`
                        +'give me a creative description of hero. Imagine vivid normal life of him/her.'
                    },
                ];
                
                for (let i = 1; i < keySentenses.length; i++){
                    chat.push({role: 'assistant', content: keySentenses[i]})
                    chat.push({role: 'system', content: steps[i]})
                    chat.push({role: 'user', content: 'give me a creative script for this section in three sentences(strict limit). Start with \'Then, \'. What happend to the hero/heroin?'})
                }
    
                let text = await AnswerChat({chat});
                setKeySentenses(keySentenses => [...keySentenses, text.replace(/^[ .,\n]+|(Then, )|(Sure, )/g,'')])
            }catch(e){
                console.error(e)
            }
        })()
                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[keySentenses])

    React.useEffect(()=>{
        if (koreanSentences.length >= keySentenses.length){
            return;
        };
        if (isKoreanLoading){
            return;
        }
        setIsKoreanLoading(true);
        (async()=>{
            const lastSentence = keySentenses[keySentenses.length-1];
            let res = await Translate({text: lastSentence});
            setKoreanSentenses(koreanSentences => [...koreanSentences, res])
            setIsKoreanLoading(false);
        })();
    },[isKoreanLoading, keySentenses, koreanSentences.length])

    React.useEffect(()=>{
    },[setting])
    
    return (<div style={{paddingTop: 20}}>
        <h1>{"GPT3 + Stable Diffusion"}</h1>
        <Select value={model} onChange={event => {
            setKeySentenses([]);
            setImages({});
            setKeySentenses([]);
            setModel(event.target.value);
        }}>
            <MenuItem value={"vintedois"}>{"vintedois"}</MenuItem>
            <MenuItem value={"stabilityai"}>{"stabilityai"}</MenuItem>
        </Select>
        <label>{"설정"}</label><TextField
            fullWidth multiline minRows={3}
            value={setting} onChange={(event) => setSetting(event.target.value)}
        />
        <label>{"스크립트"}</label><TextField
            fullWidth multiline minRows={3}
            value={prompt} onChange={(event) => setPrompt(event.target.value)}
        />
        <Button
            fullWidth variant={'contained'}
            disabled={isLoading || isKoreanLoading}
            onClick={async()=>{
                setIsLoading(true);
                setKeySentenses([]);
                setImages({});
                setKoreanSentenses([]);
                try{
                    let res = await Translate({source: 'ko', target: 'en', text: prompt})
                    setKeySentenses([res])
                }catch(e){
                    console.error(e)
                    setIsLoading(false);
                }
                setIsLoading(false);
                
                let res = await Translate({source: 'ko', target: 'en', text: setting});
                let argumented = await CompleteText({
                    prompt: 'situation: '+res
                        +" following this, describe outfit and circumstances in thirty words."
                        +" The description will be compsed with short sentences that is answering folloing questions:"
                        +" outfit detail of characters."
                        +"\nnew description:",
                    max_tokens: 1024
                })
                setEngSetting(argumented.replace(/^[ .,\n]+/g,''));
            
            }}
        >
            {"스크립트 생성"}
        </Button>
        <br/>
        <div>
            {keySentenses.map( (row,index) => <div style={{display:'flex', wordBreak: 'keep-all'}}>
                <div style={{width: 'calc(50% - 20px)', padding: 10, fontSize: '0.8rem'}}>
                    {index+1}/{steps.length}<br/>
                    <Tooltip title={koreanSentences[index] || ""}><div style={{lineHeight: '2.2rem'}}>{(keySentenses[index] || "")}</div></Tooltip>
                    <Button disabled={isLoading || isKoreanLoading} variant={"outlined"} onClick={async ()=>{
                        setIsLoading(true);
                        const lastSentence = keySentenses[index];
                        /*
                        let sceneDescription = await CompleteText({
                            prompt: lastSentence.trim()
                                +" following this, describe a long shot, outfit and circumstances in thirty words."
                                +" The description will be composed with short sentences that is answering folloing questions:"
                                +" what are they doing. background description. how the light ray to be seemed."
                                +" The background of scene should related with: '"+engSetting+"'"
                                +" use notation '(the man)' if 'the heroic character' is a man or use '(the girl)' if 'the heroic character' is a woman, to represent 'the heroic character'. Do not use a name of heroic character."
                                +` describe extra character and what are they doing and their emotion on face, relevent to '${lastSentence.trim()}'.`
                                +` Use devices or gadgets as metaphor to describe some abstract values like leadership, strength, love, or etc.`
                                +"\nnew description:",
                            max_tokens: 1024
                        });*/
                        let sceneDescription = await AnswerChat({
                            chat: [
                                {role: 'system', content: lastSentence},
                                {role: 'user', content: 
                                    " following this, describe a long shot, outfit and circumstances in thirty words."
                                    +" The description will be composed with short sentences that is answering folloing questions:"
                                    +" what are they doing. background description. how the light ray to be seemed."
                                    +" The background of scene should related with: '"+engSetting+"'"
                                    +" use notation '(the man)' if 'the heroic character' is a man or use '(the girl)' if 'the heroic character' is a woman, to represent 'the heroic character'. Do not use a name of heroic character."
                                    +` describe extra character's wearing and position and gesture. But do not add any line. relevent to '${lastSentence.trim()}'.`
                                    +` Use devices or gadgets as metaphor to describe some abstract values like leadership, strength, love, or etc.`
                                }
                            ]
                        })
                        let res = await ImageGeneration({model, query: [sceneDescription.replace(/\n/g,' ')+' 3.0', prompt_prefix, prompt_postfix].filter(prompt => prompt).join('|')});
                        setImages(images => ({
                            ...images,
                            [index]: {description: sceneDescription, image: res},
                        }))
                        setIsLoading(false);
                    }}>
                        {"다시 시도"}
                    </Button>
                </div>
                <div style={{width: 'calc(50% - 20px)', padding: 10}}>
                    <Tooltip title={(images[index] || {}).description || ""}>
                        <img style={{width: '100%', objectFit:'contain'}}
                            src={(images[index] || {}).image || ""} onError={(event)=>{
                            setTimeout(()=>{
                                if (images[index]){
                                    event.target.src = (images[index] || {}).image+'?t='+(new Date()).getTime();
                                }
                            },5000); 
                        }} alt={(images[index] || {}).description}/>
                    </Tooltip>
                </div>
            </div>)}
        </div>
        <br/>
        {isLoading?<img style={{width: '100%', height:'200px', objectFit: 'contain', objectPosition: 'center'}} src={"https://static.webtoon.today/ddah/loading-circle.gif"} alt={"loading"}/>:<></>}
    </div>);
}

export default ScriptAndCut;