import React from 'react';

import { Button, Collapse, IconButton, TextField, } from '@material-ui/core';
import {GoogleLogin} from 'react-google-login';

import ProfileComponent from '../Component/Profile';
import LoadingCircle from '../Component/LoadingCircle';

import { logBehavior } from '../Data/Behavior';
import ss from '../Data/Session';

import './Setting.scss';
import { fn, validateEmailForm } from '../Functions';
import { Close, Email, MoreVert, Warning } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

/*
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookLoginButton, } from "react-social-login-buttons";
const FACEBOOK_CID=window.location.host.endsWith("webtoon.today")
    ?"619059982083533"
    :"685998568617743";
*/
const GOOGLE_CID="1093253062710-dh2542i4vf7soiqjji199vcr0i02d7vh.apps.googleusercontent.com";


const Setting = (props) => {

    let queryString = window.location.search.replace('?','');
    let [queries,] = React.useState( Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri)))) );
    
    const [isLoading, setIsLoading] = React.useState(false);

    const [session, setSession] = React.useState(ss.getCurrentSession());

    const [isTryingWithEmail, setIsTryingWithEmail] = React.useState(false);
    const [didEmailSent, setDidEmailSent] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
    const [token, setToken] = React.useState(null);
    const [tokenErrorMessage, setTokenErrorMessage] = React.useState("");

    React.useEffect(()=>{
        setTokenErrorMessage("")
    }, [token])
    
    if (session.email && queries.redirectTo) {
        setTimeout(()=>fn.goto(queries.redirectTo), 100);
    }

    React.useEffect(()=>{
        const sessionUpdate = ()=>{
            setSession(ss.getCurrentSession());
        }
        ss.registerSessionCallback(sessionUpdate)
    },[]);

    React.useEffect(()=>{

        let timer = setTimeout(()=>{
            (async()=>{
                const {email, token} = queries;
                if (email && token){
                    setIsLoading(true);
                    let res = await ss.EmailSignin(email, token);
                    if (res){
                        logBehavior('loginSuccess');
                    }else{
                        setTokenErrorMessage("인증에 실패했습니다. 다시 확인해주세요.")
                    }
                    setIsLoading(false);
                }
            })();
        },500)

        return ()=>{
            clearTimeout(timer);
        }
    },[queries])

    window.canonicalPath = '/setting';

    if (!session || !session.name){
        return (<div className={"SettingStyle"}>
            <div className={"GradientBackground"}>
            </div>
            <div className={"SettingContainer"}>
                <div className={"Section"}>
                    <div className={"SectionHeader"}>{"로그인"}</div>
                    <div className={"SectionBody"}>
                        <Collapse in={emailErrorMessage.length > 0 || tokenErrorMessage.length > 0}>
                            <Alert severity="error" action={<IconButton size={"small"} onClick={()=>setEmailErrorMessage("") && setTokenErrorMessage("")}><Close/></IconButton>}>{emailErrorMessage || tokenErrorMessage}</Alert>
                        </Collapse>
                        <div style={{paddingTop:100}}></div>
                        <img className={"BigWhiteLogo"} src='/logo-white.png' alt={"webtoon today logo"}/>
                        <div style={{paddingTop:100}}></div>
                        <GoogleLogin
                            clientId={GOOGLE_CID}
                            redirectUri="/"
                            buttonText="SIGN IN WITH GOOGLE"
                            theme={"light"}
                            onSuccess={async (response) => {
                                setIsLoading(true);
                                let res = await ss.GoogleSignin(response);
                                if (res) {
                                    logBehavior('loginSuccess',)
                                }
                                setIsLoading(false);
                            }}
                            onFailure={async (response) => {
                                setIsLoading(true);
                                await ss.GoogleSignin(response);
                                logBehavior('loginFailed',)
                                setIsLoading(false);
                            }}
                            cookiePolicy={'single_host_origin'}
                        />
                        {isTryingWithEmail
                            ?<div className={"EmailContainer"}>
                                
                                <TextField
                                    variant={"outlined"} style={{width:210, marginTop: 20}}
                                    defaultValue={email || ""} onChange={(event) => {setEmail(event.target.value)}}
                                    onKeyPress={(event) => event.key === 'Enter'?event.target.blur():""}
                                    placeholder={"Email"}
                                    inputProps={{style: {padding:10}}}
                                    disabled={didEmailSent}
                                />
                                <Button variant={"contained"} className={"StyledButton"}
                                    disabled={didEmailSent} onClick={()=>{
                                    if (!validateEmailForm(email)){
                                        setEmailErrorMessage("이메일 주소가 올바르지 않습니다.");
                                        return;
                                    }
                                    setIsLoading(true);
                                    ss.EmailSendOTP(email);
                                    setDidEmailSent(true);
                                    setIsLoading(false);
                                    setEmailErrorMessage("");
                                }}>{"인증하기"}</Button>
                                {didEmailSent
                                    ?<>
                                        <TextField placeholder={"인증코드"} error={!!tokenErrorMessage}  inputProps={{style:{textAlign:"center"}}} value={token || ""} onChange={(event) => {setToken(event.target.value)}}/>
                                        <Button variant={"contained"} className={"StyledButton"}
                                            disabled={!token || token.length === 0}
                                            onClick={async ()=>{
                                                setIsLoading(true);
                                                let res = await ss.EmailSignin(email, token);
                                                if (res){
                                                    logBehavior('loginSuccess');
                                                }else{
                                                    setTokenErrorMessage("인증에 실패했습니다. 다시 확인해주세요.")
                                                }
                                                setIsLoading(false);
                                            }}
                                        ><Email/><div style={{width: 200}}>{"이메일로 로그인"}</div></Button>
                                    </>
                                    :<></>}
                            </div>
                            :<Button variant={"contained"} className={"StyledButton"}
                                onClick={()=>{ setIsTryingWithEmail(true); }
                            }><Email/><div style={{width: 200}}>{"이메일로 로그인"}</div></Button>
                        }
                    </div>
                </div>
                <div className={"KakaoNotice"}>
                    <Warning/>{"카카오톡 브라우저는 "}
                    <MoreVert className={"WithBG"}/>{" 버튼을 눌러 "}
                    <code>{"다른브라우저로 보기"}</code>{"를 이용해주세요."}
                </div>
            </div>
            <LoadingCircle show={isLoading}/>
        </div>);
    }else{
        return (<div className={"SettingStyle"}>
            <div className={"SettingContainer"}>
                <div className={"Section"}>
                    <div className={"SectionHeader"}>{"프로필"}</div>
                    <div className={"SectionBody"}>
                        <ProfileComponent
                            givenProfile={{
                                addressid: null,
                                ...(ss.getCurrentSession())
                            }}
                            windowSize={{width: window.innerWidth, height: window.innerHeight}}
                        />
                        <Button
                            variant={"outlined"} className={"LogoutButton"} color={"secondary"} fullWidth
                            onClick={()=>{ss.logout()}}
                        >
                            {"로그아웃"}
                        </Button>
                    
                    </div>
                </div>
            </div>
        </div>);
    }

};

export default Setting;