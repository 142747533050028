import axios from 'axios';

export const readTrend = async ({query}) => {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/trend', {query}, {
        });

        if (res.data && res.data.code === 200){

            return res.data.data;
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}