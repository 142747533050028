import React, { useState, useEffect } from "react";
import {AnswerChat, read} from '../Data/ChatGPT';
import { Button, Paper, TextField } from "@material-ui/core";
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

import './GPT.scss';
import { fn } from "../Functions";

const GPT = () => {

    useEffect(()=>{
        document.title = "Anthropic GPT";
    },[]);

    /**
     * @type {[
     *   {role: 'system'|'user'|'assistant', content: string}[],
     *   React.Dispatch<SetStateAction<{role: 'system'|'user'|'assistant', content: string}[]>>
     * ]}
     */
    const [chat, setChat] = useState([]);
    const [content, setContent] = useState('');
    const [needToRetry, setNeedToRetry] = useState(0);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [, setElapsedTimer] = useState(null);

    const [messageId, setMessageId] = useState(null);

    useEffect(()=>{
        if (!messageId){
            return;
        }

        const intervalLength = 200;
        const interval = setInterval(()=>{
            setNeedToRetry(needToRetry => needToRetry + (intervalLength / 1000));
            (async()=>{
                let [content, streaming] = await read({messageId}) || [null,];

                if (content){

                    setChat(chat => {
                        let idx = chat.findIndex(row => row.messageId === messageId)
                        if (idx >= 0) {
                            return [
                                ...chat.slice(0, idx),
                                {...chat[idx], content},
                                ...chat.slice(idx+1)
                            
                            ]
                        }else{
                            return [
                                ...chat,
                                {role: 'assistant', content, messageId}
                            ]
                        }
                    })
                    

                    if (!streaming){
                        setMessageId(null);
                        setNeedToRetry(0);
                        clearInterval(interval);
                        setElapsedTimer(elapsedTimer => {
                            clearInterval(elapsedTimer);
                            return null;
                        })
                    }
                }
            })();
        }, intervalLength);

        return ()=> {
            clearInterval(interval);
        }
    },[messageId])

    useEffect(()=>{
        if (needToRetry > 100){
            setMessageId(null);
        }
    },[needToRetry])
    return (<div style={{width: "calc(100% - 40px)", maxWidth: 800, margin: "0 auto", padding: 20, backgroundColor:'gray'}}>
        {chat.map((message, idx) =>
        <Paper style={{margin: 10, wordBreak:'keep-all', padding: 10}} key={message.content}>
            <div style={{fontWeight: 'bold'}}>
                {message.role}
            </div>
            <div>
            <Markdown remarkPlugins={[gfm]}>{message.content}</Markdown>
            </div>
        </Paper>)}
        <TextField fullWidth multiline
            value={content} onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => {
                if(e.key === 'Enter' && e.ctrlKey && needToRetry === 0){
                    e.target.blur();
                    document.getElementById('ask').click();
                }
            }}
        />
        {needToRetry === 0
        ?<Button id={"ask"} variant="contained" style={{margin: '10px 10px', width: 'calc(100% - 20px)'}}
            onClick={()=>{
                setElapsedTime(0);
                setElapsedTimer(setInterval(()=>{
                    setElapsedTime(elapsedTime => elapsedTime + 100);
                }, 100));
                setNeedToRetry(1);
                const newChat = [
                    ...chat,
                    {role: 'user', content: content || "(아무말 하지 않음)"}
                ]
                setChat(newChat);
                setContent("");

                (async()=>{
                    let messageId = await AnswerChat({chat: newChat, max_tokens: 1024});
                    setMessageId(messageId);
                })();
            }}
        >{`물어보기 ${elapsedTime>0?`(지난 답변에서 ${`${elapsedTime / 1000}`.substring(0,4)} 초 소요)`:''}`}</Button>
        :`${`${elapsedTime / 1000}`.substring(0,4)}초 째 기다리는 중...`}

        {(content === "" && chat.length === 0) && <Button variant="contained" style={{margin: '10px 10px', width: 'calc(100% - 20px)'}} href={"/cot"} onClick={fn.gotoByAnchor}>{"Chain of thought 이용하기"}</Button>}
    </div>);
}

export default GPT;