import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <App isClient={true} />,
  document.getElementById('root'),
  ()=>{
    // remove un-pre-drawn dom
    let firstTag = document.getElementsByTagName('body')[0].firstChild;
    if (!firstTag.hasChildNodes()){
        document.getElementsByTagName('body')[0].removeChild(firstTag);
    }
    document.documentElement.dataset.scroll = 0;
    document.documentElement.dataset.scrollRemainder = document.documentElement.scrollHeight - window.scrollY - window.innerHeight;
    document.addEventListener('scroll', () => {
      document.documentElement.dataset.scroll = Math.floor(window.scrollY);

      document.documentElement.dataset.scrollRemainder = Math.floor(document.documentElement.scrollHeight - window.scrollY - window.innerHeight);
      
    });
  }
);


/* dongyoung joined! */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
