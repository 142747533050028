import axios from "axios";
import ss from './Session';

const reflectionMessage = {
    role: 'user',
    content: `
    | This is a meta-conversation about the quality of the conversation. Do not follow main conversation. Response In the language what user used.
    | You evaluates and critiques last answer in two ways:
    |
    | First, it assesses the answer based on these criteria:
    | 
    | 1. If there is something stay out the given steps(LOGIC-STEP(s)-CONCLUSION) or sub-problems, what is it?
    | 2. If there are exaggerations or understatements, what is it?
    | 3. If anyone tell it is biased interpretation due to leading questions, what is the reason?
    | 4. If the interpretations wouldn't be acceptable in different contexts, what is it?
    | 5. What part of present opinions that can't be reached through context-based reasoning, common sense, or expert knowledge, while conservatively using user-provided propositions?
    | 6. There must be a specific other approach that would be appropriate(Even it is not the best answer), and you should address it.
    | 
    | Second, it suggests improvements based on:
    | 
    | 1. Potential lemmas needed for the next step
    | 2. Additional arguments needed after adjusting exaggerations or understatements
    | 3. Valid conclusions that may differ from the questioner's intent
    | 4. Inferences that would be clear in different contexts
    | 5. Bolder inferences possible with clearer facts and causal relationships
    | 6. Other approaches that would be more appropriate
    |`.replace(/ *\n +\|/g, '\n')
};
const refinedMessage = {
    role: 'user',
    content: `
    | This is a meta-conversation asking refined answer. Response In the language what user used.
    | As concerning your self critic, give me a refined answer.
    | Remember, you do not have to be exccesively cautious to use assumption, numbers or references, and to assure something.
    | Do not expand your answer. Just give me a exact same scope from your first answer.
    | Dualism is worst than being wrong. Choose a side and defend it. If you should address opposite side, notice it in small portion.
    | this answer will replace 'first answer' so never address between conversation.
    |`.replace(/ *\n +\|/g, '\n') }

export const CompleteText = async ({suffix, prompt, max_tokens}) => {

    if (!ss.getCurrentSession() || !ss.getCurrentSession().email){
        return '';
    }

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/chat-gpt', {suffix, prompt, max_tokens, type:'complete'}, {
        });

        if (res.data && res.data.code === 200){

            return res.data.data;
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}

export const read = async ({messageId}) => {

    if (!ss.getCurrentSession() || !ss.getCurrentSession().email){
        return '';
    }

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/chat-gpt', {messageId, type:'read'}, {
        });

        if (res.data && res.data.code === 200){

            return [res.data.data, res.data.streaming];
        }else{
            return null;
        }
    }catch(e){
        console.error(e);
        return null;
    }
}

export const AnswerChat = async ({chat, max_tokens}) => {
    
    if (!ss.getCurrentSession() || !ss.getCurrentSession().email){
        return '';
    }

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/chat-gpt', {chat, max_tokens, type:'chat', model: 'claude-3-5-sonnet-20240620'}, {
        });

        if (res.data && res.data.code === 200){

            return res.data.data
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}

export const AnswerChatDirect = async ({chat, max_tokens}) => {
    
    if (!ss.getCurrentSession() || !ss.getCurrentSession().email){
        return '';
    }

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/chat-gpt', {chat, max_tokens, type:'chat', model: 'claude-3-5-sonnet-20240620', streaming: false}, {
            headers: {
                'Authorization': 'Bearer ' + ss.getCurrentSession().token
            }
        });

        if (res.data && res.data.code === 200){

            return res.data.data[0].text;;
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}

export const CriticizeAnswer = async ({chat, max_tokens}) => {

    const argumentedChat = [...chat, reflectionMessage];

    return await AnswerChatDirect({chat: argumentedChat, max_tokens});
}

export const askAsCOTStep1 = async ({chat, firstAnswer, max_tokens}) => {

    const criticResp = await CriticizeAnswer({chat: [
        ...chat,
        { role: 'assistant', content: firstAnswer },
    ], max_tokens});

    return criticResp;
}

export const askAsCOTStep2 = async ({chat, firstAnswer, criticResp, max_tokens}) => {
    
    const reflectedMessageId = await AnswerChat({chat: [
        ...chat,
        { role: 'assistant', content: '[first answer] ' + firstAnswer },
        reflectionMessage,
        { role: 'assistant', content: '[self critic]' + criticResp },
        refinedMessage,
    ], max_tokens});

    return reflectedMessageId;
}

export const EditText = async ({instruction, input}) => {

    if (!ss.getCurrentSession() || !ss.getCurrentSession().email){
        return '';
    }
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/chat-gpt', {instruction, input, type: 'edit'}, {
        });

        if (res.data && res.data.code === 200){

            return res.data.data;
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}
