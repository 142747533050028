import React from 'react';

import './SideBar.scss';

// eslint-disable-next-line no-empty-pattern
const SideBar = ({}) => {

    return (<div className='SideBar'>

    </div>);
}

export default SideBar;