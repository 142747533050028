import React, { useEffect, useRef, useState } from 'react';
import { Viewer } from 'hwp.js';

import './HWP.scss';
import { Button } from '@material-ui/core';

const HWP = () => {

    /**@type {[File|null, React.Dispatch<React.SetStateAction<File|null>>]} */
    const [file, setFile] = useState(null);

    useEffect(()=>{
        if (!file){
            return;
        }

        if (!file.name.endsWith('.hwp')){
            alert('.hwp 파일만 읽을 수 있어요.');
            setFile(null);
        }

        const reader = new FileReader();

        reader.onload = ()=>{
            new Viewer(ref.current, reader.result);
        };

        reader.readAsBinaryString(file)

        return () => {
            reader.abort();
        }
    },[file])

    const ref = useRef(null);

    return (<div className={"HWP"}>
        <div className={"Header"}>
            <h2><img src={"https://static.webtoon.today/ddah/logo-04.png"} width={32} height={32} alt={"wt"}/>{"한글 뷰어"}</h2>
            <h3>{"파일은 서버로 전송되지 않습니다."} {file && <Button size={"small"} onClick={()=>{setFile(null)}}>{"파일 닫기"}</Button>}</h3>
        </div>
        {file && <div className={"Viewer"} ref={ref}>
        </div>}
        {!file && <div
            className='DragDrop'
            onClick={(e) => e.target?.querySelector('input')?.click()}
            onDragOver={(e)=>{
                e.stopPropagation();
                e.preventDefault();
            }}
            onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (e.dataTransfer.files && e.dataTransfer.files.length > 0){
                    setFile(e.dataTransfer.files[0])
                }
            }}
        >
            {"여기에 파일을 떨어뜨려 주세요"}
            <input type={'file'} onChange={(e) => setFile(e.target.files[0])} accept='.hwp' />
        </div>}
    </div>);
};

export default HWP;