import './Home.scss';
import React, { useEffect } from 'react';
import SideBar from '../Component/SideBar';

import Trend from './Trend';
import ScriptAndCut from './ScriptAndCut';
import ScriptAndCutLineByLine from './ScriptAndCutLineByLine';
import ImageGen from './ImageGen';
import Knn from './Knn';
import GPT from './GPT';
import HWP from './HWP';
import MarketingGuide from './MarketingGuide';
import COT from './COT';
import ss from '../Data/Session';
import { fn } from '../Functions';


const Home = ({service, params}) => {

    useEffect(() => {
        setTimeout(() => {
            if (!ss.getCurrentSession().email) {
                fn.goto(`/setting?redirectTo=/${service}`);
            }
        }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    return (
        <div className={'WebtoonsAIHome'}>
            <SideBar/>
            <div className={'MainBody'}>
                {//service === 'scriptgen'?<ScriptGen/>
                service === 'trend'    ?<Trend params={params}/>
                :service === 'script'  ?<ScriptAndCut/>
                :service === 'linebyline'  ?<ScriptAndCutLineByLine/>
                :service === "image"   ?<ImageGen/>
                :service === "knn"     ?<Knn/>
                :service === "namu"    ?<GPT/>
                :service === "gpt"     ?<GPT/>
                :service === "cot"     ?<COT/>
                :service === "hwp"     ?<HWP/>
                :service === 'marketing' ?<MarketingGuide/>
                :<></>}
            </div>
        </div>
    )
}

export default Home;