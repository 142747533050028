import React from "react";
import {readTrend} from '../Data/Trend';
import { extent } from 'd3-array';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import {AxisLeft, AxisBottom} from '@visx/axis';


import './Trend.scss';

const Trend = ({params}) => {
    const query = params[0]

    const [gTimeseries, setGTimeseries] = React.useState([]);
    const [nTimeseries, setNTimeseries] = React.useState([]);

    // horizontal, x scale

    const timeScale = scaleLinear({
        range: [0, 500],
        domain: extent(nTimeseries, (row)=> new Date(row.label).getTime()),
        nice: true
    });

    // vertical, y scale
    const rdScale = scaleLinear({
        range: [300, 0],
        domain: extent(nTimeseries, (row)=> row.value),
        nice: true,
    });

    React.useEffect(()=>{
        (async ()=>{
            let trendData = await readTrend({query});
            if (!trendData){
                return;
            }

            setGTimeseries(trendData.gtrend.trends_interest_over_time.data
                .map(row => ({
                    label: row.date_utc.substring(0,10),
                    value: row.values[0].value,
                })));
            setNTimeseries(trendData.ntrend.results[0].data
                .map(row => ({
                    label: row.period,
                    value: row.ratio
                })))
        })();
    },[query])

    return (<div>
        <svg width={600} height={400}>
            <rect x={0} y={0} width={600} height={400} fill={'#EEE'}/>
            <AxisLeft
                tickTextFill={'#EDF2F7'}
                stroke={'#EDF2F7'}
                tickStroke={'#EDF2F7'}
                scale={rdScale}
                tickLabelProps={() => ({
                    fill: '#EDF2F7',
                    fontSize: 11,
                    textAnchor: 'end',
                })} 
            />
            <AxisBottom
                scale={timeScale}
                stroke={'#EDF2F7'}
                tickFormat={(row)=>row.label}
                tickStroke={'#EDF2F7'}
                tickTextFill={'#EDF2F7'}
                top={400}
                tickLabelProps={() => ({
                    fill: '#EDF2F7',
                    fontSize: 11,
                    textAnchor: 'middle',
                })} 
            />
            <Group left={50} top={50} width={500} height={300}>
                <LinePath
                    key={'nseries'}
                    stroke={"#3E3"}
                    strokeWidth={3}
                    data={nTimeseries}
                    x={row => timeScale(new Date(row.label).getTime()) ?? 0}
                    y={row => rdScale(row.value) ?? 0}
                />
                <LinePath
                    key={'gseries'}
                    stroke={"#E33"}
                    strokeWidth={3}
                    data={gTimeseries}
                    x={row => timeScale(new Date(row.label).getTime()) ?? 0}
                    y={row => rdScale(row.value) ?? 0}
                />
            </Group>
        </svg>
    </div>);
}

export default Trend;