import axios from "axios";

export const list = async () => {

    try{
        let res = await axios.get('https://challenge-api.webtoon.today/ai/image-knn/list', {});

        if (res.data && res.data.code === 200){

            return res.data.data;
        }else{
            return {distances: [], labels: []};
        }
    }catch(e){
        console.error(e);
        return {distances: [], labels: []};
    }
}


export const knn = async ({target, by_face=false}) => {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/image-knn', {target, by_face});

        if (res.data && res.data.code === 200){

            return res.data.data;
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}