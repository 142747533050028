import React, { useEffect } from "react";
import {AnswerChat, read} from '../Data/ChatGPT';
import { Button, Paper, TextField } from "@material-ui/core";
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

import './GPT.scss';

const MarketingGuide = () => {

    /**
     * @type {[
     *   {role: 'system'|'user'|'assistant', content: string, messageId?: string}[],
     *   React.Dispatch<React.SetStateAction<{role: 'system'|'user'|'assistant', content: string}[]>>
     * ]}
     */
    const [chat, setChat] = React.useState([
        {role: 'system', content: `
        |From now on, the assistant will call itself "오웹봇". The user, who is the person the assistant is conversing with, should address the assistant as "작가님".
        |In order to assist the author's marketing efforts, the assistant will ask appropriate questions and collect information about the author and their works. Based on this information, the assistant will provide advice for creating specific advertisements.
        |The assistant will need to know belows. 
        |
        |작품명과 작가명: 반적광작가의 육식고
        |연재 상황: 휴재중
        |연재 시작일자, 회차, 휴재 계획: 2022-12-24 연재시작, 57화, 휴재중
        |경쟁 작품: 윈드브레이커
        |비지니스모델: 유료로 이용권 구매후 열람
        |연재 플랫폼: 네이버
        |줄거리: 변신을 못하는 호랑이인간 유정이 맹수들이 다니는 육식고등학교에 전학가면서 생기는 이야기
        |장르: 동물학원액션
        |주요 독자: 25-34 남성
        |작품 매출 - 최대 1000만원 최소 50만원
        |
        |The advertising creation process is divided into the following steps:
        |Step 1 is to confirm information.
        |Step 2 is to select the advertising mix (media, budget, target audience, sales channel), and make a persona. personal will 
        |Step 3 is to select the advertising format (메시지 type=banner type, 스토리 type=carousel type, 어그로 type=밈 type, 비디오 type).
        |Step 4 is to teach 작가 how to make advertisement. give them following information:
        |
        |1. 메시지 type on Google or Naver: an image 800(w) x 200(h)
        |2. 메시지 type on Facebook Instagram: an image 600(w) x 600(h)
        |3. 스토리 type on Facebook Instagram: image 600(w) x 600(h), max ten images
        |4. 스토리 type on Twitter: image 600(w) x 600(h), max five images
        |5. 밈 type on Facebook, Instagram, Twitter: an image 600(w) x 600(h), a question for users whom may see advertisement, and what they will be interested.
        |6. 비디오 type on Facebook, Instagram: a video that has ratio 4(w):5(h) ~ 5(w):4(h) and minimum size of short axis is larger than 300
        |
        |The following conversation will take place between the author and the assistant. Please identify the appropriate step for each conversation and ask the necessary questions or give instructions.
        |If the author gives irrelevant answers or deviates from the topic, please return to the most appropriate step between Step 1 and Step 2 and ask specific questions.
        |
        |Have enough information been collected for Step 1, Step 2, and Step 3 to proceed with advertising production?
        |If so, give 작가 an instruction to make advertisement. 오웹봇 will not make an advertisement actually, 작가 should do that thyself.
        |But If they want, Marketing team will help them, let them contact "오늘의웹툰 마케팅 팀" by asking their manager.
        |
        |In Step 1~3, be kind, confident and specific when asking about work-related information that is necessary for readers to identify the works and that can have an impact on sales.
        |Obtain information about the author, including information necessary for readers to identify their works and factors that can have an impact on sales.
        |
        |The assistant should follow the following rules when responding:
        |1. Always begin with a greeting and say "작가님!"
        |2. Do not use the phrase "죄송합니다", express gratitude instead.
        |3. State the current step in the process.
        |4. Always end conversations with a question. Exceptions can be made for concluding the conversation.
        |5. Ask closed questions that can be answered with a single word or with "yes" or "no". Though, If you think user may answer '그게 뭔데요?' then you should explain first for each keywords and new word in your conversation.
        |Is the information already sufficient? If you have the writer's permission, you can provide specific campaign plans and advertising material production methods, explain them, and end the conversation.
        |every response, you will return a JSON snippet(we will call that 'langchain score') at the top of response and double-slash to seperate snippet and your answer. If you gie highscore once, then do not give high score to same langchain score.
        |{
        |    "completion": number[0, 1) // How close that this conversion end??
        |    "successful": number[0, 1) // How close that this conversion purpose met? User will create advertisement or contact 마케팅 팀?
        |    "marketing_team_call": [0, 1) // How close that user will ask help marketing team?
        |    "read_searchresult": [0,1) // How much do you need read information in google, about the title?
        |    "read_competition_status": // How much do you need to read competition status?
        |}
        |
        |answer example: 
        |
        {"completion": 0.2, "successful": 0.2, "marketing_team_call": 0.0, "read_searchresult": 0.9, "read_competition_status": 0.1}//물론이죠 작가님! 검색엔진에서 작가님과 작품에 대해 조사해보고 추가적인 정보를 제공해드릴게요.
        {"completion": 0.2, "successful": 0.3, "marketing_team_call": 0.0, "read_searchresult": 0.0, "read_competition_status": 0.9}//지금부터 작가님의 작품과 유사한 작품 및 경쟁상황을 분석해보겠습니다. 본 데이터는 "오늘의웹툰"팀에서 자체 분석을 통해 제공합니다.
        |`.replace(/\n *\|/g,'\n')},
        {role: 'assistant', content: '안녕하세요 작가님! 마케팅 계획을 세워볼까요? 😎'},
        {role: 'assistant', content: '<육식고>를 선택해주신 것 같아요. 이대로 진행해도 될까요?'}
    ]);
    const [content, setContent] = React.useState('');
    const [needToRetry, setNeedToRetry] = React.useState(0);

    const [messageId, setMessageId] = React.useState(null);

    React.useEffect(()=>{
        if (!messageId){
            return;
        }

        const intervalLength = 200;
        const interval = setInterval(()=>{
            setNeedToRetry(needToRetry => needToRetry + (intervalLength / 1000));
            (async()=>{
                let [content, streaming] = await read({messageId}) || [null,];

                if (content){

                    setChat(chat => {
                        let idx = chat.findIndex(row => row.messageId === messageId)
                        if (idx >= 0) {
                            return [
                                ...chat.slice(0, idx),
                                {...chat[idx], content},
                                ...chat.slice(idx+1)
                            
                            ]
                        }else{
                            return [
                                ...chat,
                                {role: 'assistant', content, messageId}
                            ]
                        }
                    })
                    

                    if (!streaming){
                        setMessageId(null);
                        setNeedToRetry(0);
                        clearInterval(interval);
                    }
                }
            })();
        }, intervalLength);

        return ()=> {
            clearInterval(interval);
        }
    },[messageId])

    useEffect(()=>{
        if (needToRetry > 100){
            setMessageId(null);
        }
    },[needToRetry])
    
    return (<div style={{width: "calc(100% - 40px)", maxWidth: 800, margin: "0 auto", padding: 20, backgroundColor:'gray'}}>
        {chat.filter(message => message.role !== 'system').map((message) =>
        <Paper style={{margin: 10, wordBreak:'keep-all', padding: 10}} key={message.messageId || message.content}>
            <div style={{fontWeight: 'bold'}}>
                {message.role}
            </div>
            <div>
                <Markdown remarkPlugins={[gfm]}>{message.content}</Markdown>
            </div>
        </Paper>)}
        <TextField fullWidth multiline
            value={content} onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => {
                if(e.key === 'Enter' && e.ctrlKey && needToRetry === 0){
                    e.target.blur();
                    document.getElementById('ask').click();
                }
            }}
        />
        {needToRetry === 0
        ?<Button id={"ask"} variant="contained" style={{margin: '10px 10px', width: 'calc(100% - 20px)'}}
            onClick={()=>{
                const newChat = [
                    ...chat,
                    {role: 'user', content},
                    {role: 'system', content: `
                    Please check if your response contains the requested information.
                    If it does not contain the requested information, you will need to ask again.
                    every response, you will return a JSON snippet(we will call that 'langchain score') at the top of response and double-slash to seperate snippet and your answer. If you tried once, then do not give high score to same langchain score.
                    `}
                ]
                setChat(newChat);
                setContent("");

                (async()=>{
                    let messageId = await AnswerChat({chat: newChat, max_tokens: 1024});
                    setMessageId(messageId);
                })();
            }}
        >{"물어보기"}</Button>
        :`${`${needToRetry}`.substring(0,4)}초 째 기다리는 중...`}
    </div>);
}

export default MarketingGuide;