import { Button, Checkbox, MenuItem, Select, TextField } from '@material-ui/core';
import React from 'react';
import { ImageGeneration } from '../Data/ImageGeneration';

import './ScriptGen.scss';
const prompt_postfix = [
    "studio lighting delicate features finely detailed perfect art",
    "longbody -1",
    "lowres -1",
    "bad anatomy -1",
    "bad hands -1",
    "missing fingers -1",
    "extra digit -1",
    "fewer digits -1",
    "cropped -1",
    "worst quality -1",
    "low quality -1"
].join("|");


// eslint-disable-next-line no-empty-pattern
const ImageGen = ({}) => {
    
    const [isLoading, setIsLoading] = React.useState(false);

    const [prompt, setPrompt] = React.useState("");
    const [image, setImage] = React.useState("");
    const [addPreferredPrompt, setAddPreferredPrompt] = React.useState(false);

    const [model, setModel] = React.useState('stabilityai');
    
    return (<div style={{paddingTop: 20}}>
        <h1>{"GPT3 + Stable Diffusion"}</h1>
        <Select value={model} onChange={event => {
            setModel(event.target.value);
        }}>
            <MenuItem value={"stabilityai"}>{"stabilityai"}</MenuItem>
            <MenuItem value={"vintedois"}>{"vintedois"}</MenuItem>
        </Select>
        <label>{"스크립트"}</label><TextField
            fullWidth multiline minRows={3}
            value={prompt} onChange={(event) => setPrompt(event.target.value)}
        />
        <Checkbox checked={addPreferredPrompt} onChange={(event, newValue) => setAddPreferredPrompt(newValue)}/><label>{"프롬프트 강화하기"}</label>
        <Button
            fullWidth variant={'contained'}
            disabled={isLoading}
            onClick={async()=>{
                setIsLoading(true);
                try{
                    let res = await ImageGeneration({
                        query: prompt +"|"+ (addPreferredPrompt?prompt_postfix:""),
                        model
                    });
                    setImage(res);
                }catch(e){
                    console.error(e)
                    setIsLoading(false);
                }
                setIsLoading(false);
            }}
        >
            {"스크립트 생성"}
        </Button>
        <br/>
        <div>
            <div style={{width: 'calc(100% - 20px)', padding: 10}}>
                <img style={{width: '100%', objectFit:'contain'}}
                    src={image || ""} onError={(event)=>{
                    setTimeout(()=>{
                        if (image){
                            event.target.src = image+'?t='+(new Date()).getTime();
                        }
                    },2000); 
                }} alt={prompt}/>
            </div>
        </div>
        <br/>
        {isLoading?<img style={{width: '100%', height:'50px', objectFit: 'contain', objectPosition: 'center'}} src={"https://static.webtoon.today/ddah/loading-circle.gif"} alt={"loading"}/>:<></>}
    </div>);
}

export default ImageGen;