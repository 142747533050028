
import React from 'react';

const Toast = ({children = undefined, style={}, timeout=1000}) => {

    const [show, setShow] = React.useState(false);

    React.useEffect(()=>{
        if (children){
            setShow(true);    
        }
        
        const timer = setTimeout(()=>{
            setShow(false);
        }, timeout);

        return ()=>{
            clearTimeout(timer);
        }
    },[children, timeout])

    return (
    <div style={{
        zIndex: 2,
        fontSize: '1rem',
        position: 'fixed', top: 100, textAlign: 'center', display: show?'block':'none',
        width: '100vw', minHeight: 50,
        ...style
    }}>
        <div style={{
            width: 240, padding: '15px 22px',
            marginLeft: 'auto', marginRight: 'auto',
            borderRadius: 5, backgroundColor: 'rgba(1,1,1,0.8)', color:'rgb(255,255,255)',
            whiteSpace: 'pre-line', wordBreak: 'keep-all'
        }}>
            {children}
        </div>
    </div>
    )
}

export default Toast;