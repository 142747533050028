import { Checkbox, FormControlLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import {knn, list} from '../Data/Knn';

const Knn = () => {

    const [images, setImages] = React.useState([]);
    const [byFace, setByFace] = React.useState(false);

    React.useEffect(()=>{
        (async()=>{
            let newList = await list();
    
            setImages(newList.slice(0,1000));
        })();
    },[]);

    const [currentImg, setCurrentImg] = React.useState('');

    const [neighbour, setNeighbour] = React.useState({distances: [], labels: []});

    React.useEffect(()=>{
        if (!currentImg){
            return;
        }
        (async()=>{
            let neigh = await knn({target: currentImg, by_face: byFace});
            setNeighbour(neigh);
        })();
    },[byFace, currentImg])

    return (<div>
        <div >
            <Select value={currentImg} onChange={event => setCurrentImg(event.target.value)}>
                {images.map(img => 
                    <MenuItem key={img} value={img}>{img}</MenuItem>
                )}
            </Select>
            <FormControlLabel control={<Checkbox value={byFace} onChange={(event, checked)=>setByFace(checked)}/>} label={"얼굴로 유사도 찾기"}/>
        </div>
        <div>
            <h3>{"선택된 이미지"}</h3>
            {currentImg?<img style={{width: 200, objectFit:'cover', objectPosition:'center'}} src={`https://static.webtoon.today/ml230225/${currentImg}`} alt={currentImg}/>:<></>}
        </div>
        <div>
            <h3>{"선택된 이미지"}</h3>
            {neighbour.labels.map((label,idx) => <div key={label} style={{display:'flex', padding: 5, textAlign:'right'}}>
                <img
                    style={{width: 200, objectFit:'cover', objectPosition:'center'}}
                    src={`https://static.webtoon.today/ml230225/${label}`}
                    alt={label}
                    onClick={()=>{
                        setCurrentImg(label)
                    }}
                />
                <div style={{padding: 5, width: `calc(100% - 100px)`}}>{label}<br/><br/> {`${neighbour.distance[idx]}`.substring(0,6)}</div>
            </div>)}
        </div>
    </div>)
}

export default Knn;