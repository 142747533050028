import axios from "axios";
import ss from './Session';

/**
 * 
 * @param {{query:string, model: 'stabilityai'|'vintedois'}} param0 
 * @returns 
 */
export const ImageGeneration = async ({query, model='stabilityai'}) => {

    if (!ss.getCurrentSession() || !ss.getCurrentSession().email){
        return '';
    }

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ai/image-generation', {txt2img: true, query, model, props: {steps: 15}}, {
        });

        if (res.data && res.data.code === 200){

            return res.data.data;
        }else{
            return '';
        }
    }catch(e){
        console.error(e);
        return '';
    }
}
