import React from 'react';
import {BranchFunction, fn} from './Functions';
import { logBehavior } from './Data/Behavior';
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button,} from '@material-ui/core';

import Home from './Page/Home';
import NotFound from './Page/NotFound';
import Setting from './Page/Setting';

import './VirtualRouter.scss';

/** @typedef {{width: number, height: number}} size */
/** @typedef {{x: number, y: number}} point */
/**
 * @template T
 * @typedef {React.Dispatch<React.SetStateAction<T>>} set<T>
 */

 /**
  * @typedef {import('./App').globalDataType & { 
  *   goto: (urlOrPath: string) => void,
  *   path: string,
  * }} routingDataType
  * */

 /**
  * 
  * @param {string} path 
  * @param {routingDataType} newProps 
  * @returns {() => JSX.Element}
  */
const jsxBranch = (path, hash, newProps) => BranchFunction(
    path,
    [
        {
            validator: (path) => path.startsWith('/setting'),
            defaultValue: () => <Setting {...newProps} />,
        },
        {
            validator: (path) => true,
            defaultValue: () => {
                let [, service, ...params] = path.split('/').concat(['','']);
                return <Home service={service || 'scriptgen'} params={params.map(param => decodeURIComponent(param))} />
            },
        }
    ],
    () => <NotFound />,
)

/**
 * 
 * @param {import('./App').globalDataType} props 
 */
const VirtualRouter = (props) => {

    /**
     * @type {[
     *      [string, set<string>],
     *      [size, set<size>],
     *      [number, set<number>],
     * ]}
     * */
    const [path, setPath] = React.useState((props.injectedLocation || window.location.pathname).replace(window.location.origin));
    const [hash, setHash] = React.useState((props.injectedLocation || '').split('#')[1] || window.location.hash);

    React.useEffect(()=>{
        const historyListener = ()=>{
            setPath(window.location.pathname);
            setHash(window.location.hash);
        };
        window.addEventListener('popstate', historyListener);
        return ()=> {
            window.removeEventListener('popstate', historyListener);
        }
    },[])

    const [goto, ] = React.useState(() => (urlOrPath) => {
        if (urlOrPath === window.location.pathname){
            return;
        }
        logBehavior('goto', {to: urlOrPath});

        if (urlOrPath === undefined || urlOrPath === null) {
            window.history.back();
        }else if (urlOrPath.startsWith("/")){
            const path = urlOrPath.split('#')[0];
            
            const hash = urlOrPath.split('#')[1];

            if (path.startsWith("/") || path === ""){
                setPath(path);
                setHash(hash);

                window.history.pushState({}, document.title, window.location.origin + path + (hash?`#${hash}`:``));
            }
        }else {
            window.open(urlOrPath);
        }
    });
    React.useEffect(()=>{
        fn.goto = goto;
    },[goto]);

    /**
     * @type {[
     *      [boolean, set<boolean>],
     *      [string, set<string>],
     *      [string, set<string>],
     *      [(boolean)=>void, set<(boolean)=>void>],
     *      [string, set<string>],
     *      [string, set<string>],
     * ]}
     */
    const [
        [warningShown, setWarningShown],
        [warningTitle, setWarningTitle],
        [warningMessage, setWarningMessage],
        [warningShownCallback, setWarningShownCallback],
        [warningOKText, setWarningOkText],
        [warningCancelText, setWarningCancelText],
    ] = [
        React.useState(false),
        React.useState(""),
        React.useState(""),
        React.useState((isOkay) => {}),
        React.useState(""),
        React.useState(""),
    ];
    

    /**
     * 
     * @param {string} warningTitle 
     * @param {string} warningMessage 
     * @param {(boolean) => void} warningShownCallback 
     * @param {string?} warningOKText 
     * @param {string?} warningCancelText 
     */
    const [showWarning,] = React.useState(()=>(warningTitle, warningMessage, warningShownCallback, warningOKText, warningCancelText) => {
        setWarningTitle(warningTitle);
        setWarningMessage(warningMessage);
        setWarningShownCallback(warningShownCallback);
        setWarningOkText(warningOKText);
        setWarningCancelText(warningCancelText);
    });

    /** @type {routingDataType} */
    const newProps = {
        isClient: props.isClient,
        goto, path,
        showWarning
    };

    return (
    <>
        <div className={"Router"}>
            <div style={{width:'100%'}}>
                {jsxBranch(path, hash, newProps)()}
            </div>
        </div>
        <Dialog
            open={warningShown}
        >
            <DialogTitle>{warningTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{warningMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{
                    setWarningShown(false)
                    warningShownCallback(false)
                }} color="default">
                    {warningCancelText || "Close"}
                </Button>
                <Button onClick={()=>{
                    setWarningShown(true)
                    warningShownCallback(true)
                }} color="primary">
                {warningOKText || "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}

export default VirtualRouter